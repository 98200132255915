.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-logo {
  padding-left: 15%;
  color: white;
}

.navigation-link-container {
  padding-right: 15%;
}

.navigation-item {
  padding: 0 10px 0 10px;
  font-size: 15px;
  font-weight: 500;
  color: white;

  font-family: 'Roboto', sans-serif;
  text-decoration: none !important;
  /* padding-bottom: 10px; */
}

.active {
  color: #00bfff;
}

.active::after {
  border-bottom: 1px solid #262626;
  content: '';
  display: block;
  margin: 0 auto;
  width: 75%;
  position: relative;
  bottom: -10px;
}

.non-landing-text {
  color: black;
}

.header-non-landing {
  color: black !important;
  background-color: white;
  border-bottom: 1px solid black;
  box-shadow: 0px 3px 4.6px 0.3px rgba(0, 0, 0, 0.25);
}
