.loader-wrapper {
  position: absolute;
  background-color: #1D3557;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 9999;
}

.loader {
  display: inline-block;
  width: 5vw;
  height: 5vw;
  position: relative;
  border: 4px solid #F1FAEE;
  top: 45%;
  animation: loader 2s infinite ease;
}

@media (max-width: 600px) {
  .loader {
    width: 20vw;
    height: 20vw;
  }
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #EDF2F4;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
