.landing-button {
  background-color: transparent;
  /* color: white; */
  font-size: 2rem;
  border: 2px solid white; /* Green */
  padding: 0.5em 1em;
  transition: all 0.5s;
  scroll-behavior: smooth;
}

.landing-button:hover {
  background-color: #44bcd8;
  border-color: #44bcd8;
}

.landing-area {
  background-image: url(../assets/landing-background.jpg);
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.landing-area::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  content: '';
}

.landing-content {
  z-index: 1;
}

.profile-img-container {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid #fff;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}

.profile-img {
  width: 100%;
  height: auto;
  position: relative;
  top: -22px;
}

.title {
  font-size: 45px;
  color: white;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  margin: 20px 0 10px 0 !important;
}

.Typewriter__wrapper {
  font-size: 18px;
  color: white;
  font-weight: 100;
  font-family: 'Montserrat', sans-serif;

  /* -webkit-animation: typeWriter 3s infinite alternate;
  -moz-animation: typeWriter 3s infinite alternate;
  -o-animation: typeWriter 3s infinite alternate;
  animation: typeWriter 3s infinite alternate; */
  overflow: hidden;
}

.Typewriter__cursor {
  color: white;
  animation: none !important;
  font-size: 22px !important;
}

.subtitle::after {
  border-right: 1px solid white;
  content: ' ';
  position: relative;
  right: -5px;
  height: 120%;
}

.next-button {
  margin-top: 25px;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: 2px solid white;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: 100px;
  height: 40px;
}

.arrow {
  width: 3em;
  height: 3em;
  padding: 0;
  margin: 0;
  position: relative;
  top: -3px;
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.social-icons {
  height: 3rem;
  width: 3rem;
  margin: 20px 10px 0 10px;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background-color: transparent;
  transition: all 300ms ease;
  color: white;
}

.social-icons:hover {
  background-color: #00bfff;
  cursor: pointer;
}
